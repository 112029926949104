// utilities.scss

// ---
// Background & Text
// ---
@each $color, $values in $theme-colors {
    // Background color
    .bg-#{$color} {
        background: $values !important;
    }
    // Text color
    .text-#{$color} {
        color: $values !important;
    }
    // Border color
    .border-#{$color} {
        border-color: $values !important;
    }
}

.text-muted {
    color: $text-muted!important;
}
.text-white {
    .text-muted {
        color: rgba(255, 255, 255, 0.85)!important;
    }
    a {
        color: rgba(255, 255, 255, 0.85);
        @include hover-state {
            color: $white!important;
        }
    }
}

// ---
// Font Size
// ---
@each $label, $values in $font-sizing {
    .text-#{$label} {
        font-size: $values !important;
    }
}
.small, small {
    font-size: 82.5%;
}

// ---
// Page Header
// ---
.page-header {
    padding: 1.25rem .25rem;
    .heading {
        font-weight: $font-weight-semibold;
        margin-bottom: .15rem;
    }
    .sub-heading {
        font-size: 80%;
        color: $text-muted;
    }
}

// ---
// Others
// ---
.group {
    >.btn,
    >[class*=col-],
    >.card {
        margin-bottom: .5rem;
    }
}
code {
    font-size: 92.5%;
    color: #112be6;
    background: #e6f0ff;
    padding: 2px 4px;
    border-radius: 4px;
}
pre { 
    margin: 0; 
    font-size: 92.5%;
}
pre code {
    font-size: 0.85rem!important;
    margin: 0;
}
.row.no-gutters {
    >[class*=col-] {
        padding-left: 0;
        padding-right: 0;
    }
    margin-right: 0;
    margin-left: 0;
}

@media (max-width: 768px) {
    .row.block-wrapper {
        >[class*=col-]:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}
button.close {
    position: relative;
    &:hover,
    &:focus,
    &:active {
        color: inherit;
        outline: none;
    }
}
.close {
    width: 24px !important;
    height: 24px !important;
    padding: 0 2px !important;
    border-radius: 50%;
    outline: none;
    svg {
        height: 14px;
        width: 14px;
    }
}
.icon-wrapper {
    @include roundedCorners(14);
    height: 150px;
    background-color: $light-lt;
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    margin-bottom: .5rem;
    .content {
        @include roundedCorners(14);
        border-bottom: 1px solid $border-color;
        background-color: $white;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        svg {
            width: 28px;
            height: 28px;
        }
        i {
            font-size: 2rem;
        }
    }
    .text-content {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    &:hover {
        border-color: $primary;
        .content {
            border-color: $primary;
        }
    }
}

// Blur
.blur {
    background-color: rgba(168,197,255,0.25);
    -webkit-backdrop-filter: saturate(125%) blur(10px);
    -moz-backdrop-filter: saturate(125%) blur(10px);
    -ms-backdrop-filter: saturate(125%) blur(10px);
    backdrop-filter: saturate(125%) blur(10px);
}
.elevate {
    box-shadow: 0px 27px 63px -24px $shadow-color;
}

$tiles: 5;
.box {
    width: auto;
    height: 5rem;
    &-light {
        background: $light;
    }
    @for $i from 1 through $tiles {
        $n: 5*$i;
        &-light-shade-#{$n} {
            background: darken($light, $n);
        }
    }
}

@each $label, $map in $theme-lt-colors {
    $base: map-get($map, base);
    $base-color: map-get($map, base-color);
    .icon-#{$label} {
        color: $base-color;
        fill: $base
    }
}
.icon-white {
    fill: $white;
}
.icon-none {
    fill: none!important;
}

.container-sm {
    max-width: $container-width;
}

.menu-toggle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    &:active,
    &:focus {
        outline: none;
    }
    .bar {
        width: 16px;
        height: 2.5px;
        background: $dark;
        border-radius: 3px;
        transition: 0.3s ease-in-out;
    }
    .bar:nth-child(2) {
        width: 22px;
        margin: .35rem 0;
        opacity: .5;
    }
    .bar:last-child {
        width: 16px;
    }
}

a.posts-wrapper {
    display: block;
    position: relative;
    color: $dark
}

