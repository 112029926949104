// card.scss
$card-corners: 18;

.card {
    // ---
    // General Card
    // ---
    border-color: $border-color;
    box-shadow: 0 0px 2px rgba(25, 23, 99, 0.07);
    @include roundedCorners($card-corners);

    /*----------  Card Header  ----------*/
    .card-header,
    .card-footer {
        background: transparent;
        border-color: $border-color;
    }
    .card-header {
        display: flex;
        align-items: center;
        .card-title {
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
            flex-direction: column;
            h3, .h3 {
                font-size: 16px;
                font-weight: $font-weight-semibold;
            }
        }
        .card-action {
            margin-left: auto;
        }
        ~.card-img-wrapper > img {
            border-radius: 0!important;
        }
    }
    .card-header:first-child {
        @include roundedTop($card-corners);
    }
    .card-title {
        font-weight: $font-weight-semibold;
    }

    /*----------  Card Footer  ----------*/
    .card-footer:last-child {
        @include roundedBottom($card-corners);
    }

    
    /*----------  Card body  ----------*/
    .card-body {
        &:last-child {
            @include roundedBottom($card-corners);
        }
        .card-img {
            @include roundedCorners($card-corners);
        }
    }

    .card-img {
        @include roundedCorners($card-corners);
        // min-height: 120px;
        max-height: 200px;
        height: 100%;
        user-select: none;
    }
    .card-img-overlay {
        background: $overlay-gradient;
        @include roundedCorners($card-corners);
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
    }
    .card-link {
        position: relative;
        &::after {
            content: '';
            height: 3px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            border-bottom: 1px solid;
            transition: 0.15s;
        }
        &:hover::after {
            width: 100%;
        }
        &:focus::after,
        &:active::after {
            width: 100%;
        }
    }
}

// ---
// Card Horizontal
// ---
.card.card-horizontal {
    @include roundedCorners(12);
    @include hover-state {
        outline: 2px solid rgba($dark, .25)
    }
    @include active-state {
        outline-color: $primary;
    }
    .card-img {
        @include roundedLeft(12);
        @include roundedRight(0);
    }
    .card-body {
        height: 100%;
        .card-title {
            overflow: hidden;
            position: relative;
            line-height: 1.2em;
            max-height: 2.4em;
            margin-right: -1em;
            padding-right: 1em;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }
    .card-img ~.card-img-overlay {
        @include roundedLeft(12);
        @include roundedRight(0);
    }
}

// ---
// Card Image Variant
// ---
.card-img-wrapper {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
    @include noAction();

    img {
        @include transition(all .15s ease-in-out);
        height: 200px;
    }
    .card-img-overlay {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: $overlay-top;
    }
}
.card-img,
.card-img-top,
.card-img-bottom {
    background-color: $light;
    object-fit: cover;
    background-size: cover;
    position: relative;
    ~.card-header {
        border-radius: 0!important;
    }
}
.card-img-top,
.card-img-top ~.card-img-overlay {
    @include roundedTop($card-corners); 
}
.card-img-bottom,
.card-img-bottom ~.card-img-overlay {
    @include roundedBottom($card-corners);
}

.card-img-bottom,
.card-img-bottom ~.card-img-overlay {
    @include roundedBottom($card-corners);
}

.card-img-bottom ~.card-img-overlay {
    background: $overlay-bottom;
}

.card.custom-card {
    min-height: 18rem;
    position: relative;
    .card-action {
        position: absolute;
        top: .5rem;
        right: .5rem;
        z-index: 1;
    }
    .card-img {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        max-height: 100%!important;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        img {
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%) scale(1.25);
            -moz-transform: translate(-50%, -50%) scale(1.25);
            -o-transform: translate(-50%, -50%) scale(1.25);
            transform: translate(-50%, -50%) scale(1.25);
        }
    }
    .card-body {
        @include roundedCorners($card-corners);
        position: absolute;
        z-index: 2;
        background: rgba($white, .75);
        backdrop-filter: blur(25px) saturate(120%);
        bottom: 0;
        box-shadow: 0 8px 14px -4px rgba(29, 33, 50, 0.25);
        width: calc(100% - 1.35rem);
        margin: 10px;
        .card-title {
            overflow: hidden;
            position: relative;
            line-height: 1.2em;
            max-height: 3.6em;
            height: 3.6em;
            margin-right: -1em;
            padding-right: 1em;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }
}

.card.card-auth {
    @include roundedCorners(14);
    .card-body {
        padding: 0;
    }
    .card-header,
    .card-footer {
        padding: 1.25rem 2.5rem;
    }
    .auth-form {
        @include margin-top(3rem);
        @include margin-bottom(3rem);
        @include padding(1.5rem);
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }
    .auth-form-title {
        margin-bottom: 1.75rem;
        h3 {
            margin-bottom: .25rem;
        }
    }
}

.card.card-form {
    @include roundedCorners($card-corners);
    .card-header {
        background-color: $white;
        &:first-child {
            @include roundedTop($card-corners);
            @include padding(1.5rem 2rem 2.25rem);
            border-bottom: none;
            background-color: $white;
            box-shadow: 0 0 0 1px rgba($dark, .08);
        }
    }
    .card-body {
        box-shadow: 0 0 0 1px rgba($dark, .1);
        @include roundedCorners($card-corners);
        background: $white;
        @include padding(1.5rem 2rem);
        margin-top: -1rem;
        z-index: 1;
        position: relative;
    }
    .card-footer {
        background-color: $white;
        &:last-child {
            @include roundedBottom($card-corners);
            @include padding(2rem 2rem 1rem);
            margin-top: -1rem;
            border-top: none;
            background-color: $secondary-lt;
            box-shadow: 0 0 0 1px rgba($dark, .08);
        }
    }
}

.card.card-posts {
    max-width: 550px;
    margin: 0 auto 1rem;
    .card-header {
        padding: 1rem 1rem 0;
        border-bottom: 0;
        .card-action {
            .btn-icon {
                width: 30px;
                height: 30px;
            }
        }
    }
    .card-img-top {
        border-radius: 0
    }
    .card-body {
        @include roundedBottom($card-corners);
        padding: 1.25rem;
        background: $white;
        position: relative;
        box-shadow: 0 1px 0 .5px rgba($dark, .125);
        z-index: 1;
        .posts-title {
            display: block;
            font-size: $font-size-md;
            margin-bottom: 1rem;
        }
        .posts-content {
            line-height: 1.75;
        }
    }
    .card-footer {
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-link:hover {
            color: $dark;
        }
        &:last-child {
            @include roundedBottom($card-corners);
            padding: 1.75rem 1.25rem .75rem;
            margin-top: -1rem;
            border-top: none;
            background-color: $white;
            box-shadow: 0 0 0 1px rgba($dark, .08);
        }
    }
}

$single-content-width: 625px;
.card.card-single {
    margin: 0 auto 1rem;
    .card-header {
        padding: 2rem 1.25rem 1.25rem;
        max-width: calc(#{$single-content-width} + 2.5rem);
        border-bottom: 0;
        margin: 0 auto;
        width: 100%;
        .card-action {
            .btn-icon {
                width: 30px;
                height: 30px;
            }
        }
    }
    .card-body {
        @include roundedBottom($card-corners);
        padding: 2rem 1.25rem;
        background: $white;
        position: relative;
        box-shadow: 0 1px 0 .5px rgba($dark, .125);
        z-index: 1;
        .posts-content {
            line-height: 1.75;
            max-width: $single-content-width;
            margin: 0 auto;
        }
    }
    .card-footer {
        background-color: $white;
        .content-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: $single-content-width;
            margin: 0 auto;
            width: 100%;
        }
        .btn-link:hover {
            color: $dark;
        }
        &:last-child {
            @include roundedBottom($card-corners);
            padding: 1.75rem 1.25rem .75rem;
            margin-top: -1rem;
            border-top: none;
            background-color: $white;
            box-shadow: 0 0 0 1px rgba($dark, .08);
        }
    }
}