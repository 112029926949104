// nav.scss

/*----------  Navigation  ----------*/
.nav-link {
    @include roundedCorners(8);
    padding: .5rem .75rem;
    transition: background .15s ease-in-out;
    color: $dark;
    background: transparent;

    &.navbar-icon {
        @include roundedCorners(40);
        width: 38px;
        padding: 5px;
        line-height: 1;
        height: 38px;
        border: none;
        position: relative;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        svg path {
            fill: $dark;
        }
        i {
            font-size: 1.35rem;
        }
    }

    &:hover,
    &.navbar-icon:hover,
    &:focus,
    &.navbar-icon:focus,
    &:active,
    &.navbar-icon:active {
        color: $primary;
        background: $light--hover;
        svg path {
            fill: $primary;
        }
    }
    &.navbar-icon.active,
    &.active {
        color: $white;
        background: $primary;
        svg path {
            fill: $white;
        }
    }

    // ---
    // Nav Content
    // ---
    .nav-content {
        display: flex;
        align-items: center;
        flex: 1;

        // ---
        // Nav Content Icon
        // ---
        .nav-icon {
            margin-right: .35rem;
            margin-left: -.35rem;
            font-size: 1.35rem;
            align-items: center;
            justify-content: center;
            display: flex;
        }
        
        // ---
        // Nav Content Text
        // ---
        .nav-text {
            position: relative
        }
    }
}

.nav-divider {
    padding: 1rem 1.25rem .35rem;
    text-transform: uppercase;
    font-size: $font-size-sm;
    color: $text-muted--hover;
    letter-spacing: 1px;
}

@media(min-width: 991.98px) {
    .mw-navbar {
        align-items: center;
        li:not(:last-child) {
            margin-right: 5px;
        }
    }
    .navbar-expand-lg .navbar-nav .nav-link:not(.navbar-user) {
        padding-right: .75rem;
        padding-left: .75rem;
    }
}

@media(max-width: 991.98px) {
    .mw-navbar {
        li:not(:last-child) {
            margin-bottom: 8px;
        }
        .nav-link {
            padding: .5rem .75rem;
        }
    }
}

/*----------  Nav User  ----------*/
.navbar-user {
    padding: .15rem .65rem .15rem .15rem!important;
    @include roundedCorners(50);
    .nav-text {
        @include ellipsis();
        max-width: 90px;
    }
    .avatar-wrapper {
        margin-right: .5rem;
    }
}
@media(min-width: 991.98px) {
    .navbar-user {
        @include roundedCorners(30);
        padding: .25rem;
    }
}

.sidenav-sticky {
    list-style: none;
    padding-left: .5rem;
    z-index: 1;
    position: sticky;
    top: 70px;
    >li>a {
        @include roundedCorners(8);
        display: block;
        padding: 3px 8px;
        margin-bottom: .15rem;
        color: $dark;
        font-size: 85%;
        position: relative;
        &:hover, 
        &:focus, 
        &:active {
            background: $dark-lt;
            text-decoration: none;
        }
    }
}

.navbar-toggler {
    padding: 0.575rem 0.5rem;
    border-radius: 30px;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
