// votes.scss

.votes {
    display: flex;
    align-items: center;
    .counter {
        margin: 0 .5rem;
        font-size: 1rem;
        line-height: 1;
    }
    .btn-link {
        padding: .375rem .5rem .375rem .375rem;
        display: inline-flex;
        align-items: center;
        line-height: 1;
        @include hover-state {
            color: $dark;
        }
    }
}
